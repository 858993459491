import "../css/app.css";
import "@/bootstrap";

import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createRoot, hydrateRoot } from "react-dom/client";
import { ThemeProvider } from "./components/theme-provider";
import { Toaster } from "./components/ui/sonner";
import { StrictMode } from "react";

import.meta.glob(["../images/**"]);

const appName = import.meta.env.VITE_APP_NAME || "OnyxAnywhere";

createInertiaApp({
    title: (title) => `${title} · ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.tsx`,
            import.meta.glob("./pages/**/*.tsx"),
        ),
    setup({ el, App, props }) {
        const root = (
            <StrictMode>
                <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
                    <App {...props} />
                    <Toaster richColors className="" />
                </ThemeProvider>
            </StrictMode>
        );

        if (import.meta.env.SSR) {
            hydrateRoot(el, root);
            return;
        }

        createRoot(el).render(root);
    },
    progress: {
        color: "#4B5563",
    },
});
